import React from "react";

function InfoItem({ icon, text, phone }) {
    return (
        <div className="flex my-4">
            <span className="mx-6">
                <img width="25px" src={icon} alt="icon" style={{filter:" brightness(0) invert(1)"}}/>
            </span>
            {phone ? (
                <a
                    className="text-primary self-center lg:text-xl"
                    href={"https://wa.me/" + text}
                    target="blank"
                >
                    {text}
                </a>
            ) : (
                <p className="text-primary self-center lg:text-xl">{text}</p>
            )}
        </div>
    );
}

export default InfoItem;
