import React, { useState } from "react";
import { useTranslation } from "react-i18next";

function Form() {
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [msg, setMsg] = useState("");
    const { t } = useTranslation();

    const handleChange = (e, setValue) => {
        setValue(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(name, email, msg);
    };
    return (
        <form
            onSubmit={handleSubmit}
            className="text-gray-600 body-font relative"
        >
            <div className="container px-5 pb-24 mx-auto">
                <div className="lg:w-1/2 md:w-2/3 mx-auto">
                    <div className="flex flex-wrap -m-2">
                        <div className="p-2 w-1/2">
                            <div className="relative">
                                <label
                                    for="name"
                                    className="leading-7 text-sm text-primary capitalize"
                                >
                                    {t("contactUs.form.name")}
                                </label>
                                <input
                                    onChange={(e) => handleChange(e, setName)}
                                    type="text"
                                    id="name"
                                    name="name"
                                    className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                />
                            </div>
                        </div>
                        <div className="p-2 w-1/2">
                            <div className="relative">
                                <label
                                    for="email"
                                    className="leading-7 text-sm text-primary capitalize"
                                >
                                    {t("contactUs.form.email")}
                                </label>
                                <input
                                    onChange={(e) => handleChange(e, setEmail)}
                                    type="email"
                                    id="email"
                                    name="email"
                                    className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                                />
                            </div>
                        </div>
                        <div className="p-2 w-full">
                            <div className="relative">
                                <label
                                    for="message"
                                    className="leading-7 text-sm text-primary capitalize"
                                >
                                    {t("contactUs.form.msg")}
                                </label>
                                <textarea
                                    onChange={(e) => handleChange(e, setMsg)}
                                    id="message"
                                    name="message"
                                    className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:bg-white focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
                                ></textarea>
                            </div>
                        </div>
                        <div className="p-2 w-full">
                            <button className="flex mx-auto text-dark bg-secondary border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg uppercase">
                            {t("contactUs.form.submit")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
}

export default Form;
