import { useTranslation } from "react-i18next";
import InfoItem from "../infoItem/InfoItem";

export default function ContactInfo() {
  const { t } = useTranslation();
  return (
    <div className="info-container flex flex-col my-16 px-4 items-center">
      <div>
        <InfoItem
          phone={true}
          text="+9647501441551"
          icon="/assets/icons/whatsapp.svg"
        />
        <InfoItem text="info@baitalwakalat.com" icon="/assets/icons/mail.svg" />
        <InfoItem
          text={t("contactUs.location")}
          icon="/assets/icons/marker.svg"
        />
      </div>
    </div>
  );
}
