import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { MdMenu } from "react-icons/md";
import i18n from "../../i18n";

function Navbar() {
  const [navbarOpen, setNavbarOpen] = useState(false);
  const { t } = useTranslation();
  const handleLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };
  return (
    <>
      <nav className="fixed z-50 w-full min-h-28 flex flex-wrap items-center justify-between px-2 py-3 bg-dark text-gray-400 ">
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <a
              className="text-sm font-bold leading-relaxed inline-block mr-4 whitespace-nowrap uppercase text-white"
              href="/"
            >
              <img src="/logo.png" alt="logo" width="100px" />
            </a>
            <button
              className="text-white cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <MdMenu />
            </button>
          </div>
          <div
            className={
              "lg:flex flex-grow lg:flex-row flex-col" +
              (navbarOpen ? " flex" : " hidden")
            }
            id="example-navbar-danger"
          >
            <ul className="flex flex-col lg:flex-row align-left list-none lg:mx-auto">
              <li className="nav-item px-3 py-2 flex items-center uppercase font-bold text-white hover:opacity-75 text-lg ">
                <a href="/" activeClassName="border-b-4 border-solid">
                  {t("nav.home")}
                </a>
              </li>
              <li className="nav-item px-3 py-2 flex items-center uppercase font-bold text-white hover:opacity-75 text-lg">
                <a href="/#about" activeClassName="border-b-4 border-solid">
                  {t("nav.aboutUs")}
                </a>
              </li>
              <li className="nav-item px-3 py-2 flex items-center uppercase font-bold text-white hover:opacity-75 text-lg">
                <a href="/#brands" activeClassName="border-b-4 border-solid">
                  {t("nav.brands")}
                </a>
              </li>
              <li className="nav-item px-3 py-2 flex items-center uppercase font-bold text-white hover:opacity-75 text-lg">
                <a href="/#info" activeClassName="border-b-4 border-solid">
                  {t("nav.contactUs")}
                </a>
              </li>
            </ul>
            <div className="flex items-center mt-5 lg:mt-0">
              <div className="mx-2">
                <img
                  className="cursor-pointer"
                  src="/assets/usa.svg"
                  width="25px"
                  alt="english language"
                  onClick={() => {
                    handleLanguage("en");
                  }}
                />
              </div>
              <div className="mx-2">
                <img
                  className="cursor-pointer"
                  src="/assets/iraq.svg"
                  width="25px"
                  alt="arabic language"
                  onClick={() => {
                    handleLanguage("ar");
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
