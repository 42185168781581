import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      nav: {
        home: "home",
        aboutUs: "about us",
        brands: "brands",
        contactUs: "contact us",
      },
      aboutUs: {
        title: "about us",
        content:
          "We started our Cosmetic line in 2002, We are the official distributing agent of European brands in Iraq. Our work is directly in relation with Salons, Equipping Salons, and class A shops. We Proudly represent the Support and Maintenance System of Bait Al-wakalat Group which is a pioneer in the Iraq Beauty Industry for more than 20 years. Bait Al-wakalat is present in 18 cities in Iraq. Our customers and business partners trust our group for the quality of the products and services offered by us in various business sectors like Cosmetics (Professional & Retail), Manufacturing of Professional and Personal care Products.",
      },
      followUs: {
        title: "follow us",
      },
      brands: {
        title: "brands",
      },
      services: {
        title: "services",
        content:
          "WE PROVIDE SHOPS AND BEAUTY CENTERS WITH WIDE VARIETY OF PRODUCTS FROM DIFFERENT BRANDS, WE HAVE PROVIDED FOR MORE THAN 240 SALONS, CENTERS, AND CLASS A SHOPS",
      },
      contactUs: {
        title: "contact us",
        location: "Iraq/Kurdistan Region/Erbil/Dream City",
        email: "info@baitalwakalat.com",
        form: {
          name: "name",
          email: "email",
          msg: "message",
          submit: "submit",
        },
      },
    },
  },
  ar: {
    translation: {
      nav: {
        home: "الصفحة الرئيسية",
        aboutUs: "عنا",
        brands: "العلامات التجارية",
        contactUs: "تواصل معنا",
      },
      aboutUs: {
        title: "عنا",
        content:
          "بدأنا خط مستحضرات التجميل في عام 2002 ، نحن الوكيل الرسمي الموزع للعلامات التجارية الأوروبية في العراق.  يرتبط عملنا مباشرة بالصالونات وتجهيز الصالونات والمحلات التجارية من الدرجة الأولى.  نحن نمثل بفخر نظام الدعم والصيانة لمجموعة بيت الوكالات الرائدة في صناعة مستحضرات التجميل في العراق لأكثر من 20 عامًا.  بيت الوكالات موجودة في 18 مدينة في العراق. يثق عملاؤنا وشركاؤنا في العمل بمجموعتنا لجودة المنتجات والخدمات التي نقدمها في مختلف قطاعات الأعمال مثل مستحضرات التجميل (المهنية والتجزئة) وتصنيع منتجات العناية المهنية والشخصية.",
      },
      followUs: {
        title: "تابعنا",
      },
      brands: {
        title: "العلامات التجارية",
      },
      services: {
        title: "الخدمات",
        content:
          "نوفر للمحلات ومراكز التجميل مجموعة متنوعة من المنتجات من ماركات مختلفة ، وقد قدمنا ​​خدماتنا لأكثر من 240 صالونًا ومركزًا ومتاجرًا من الدرجة الأولى",
      },
      contactUs: {
        title: "تواصل معنا",
        location: "العراق / إقليم كوردستان / أربيل / دريم سيتي",
        form: {
          name: "الاسم",
          email: "البريد الالكتروني",
          msg: "الرسالة",
          submit: "ارسال",
        },
      },
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
