/* eslint-disable react/jsx-no-target-blank */
import React from "react";

function Card({ img, link }) {
    function handleClick() {
        window.open(link)
    }
    return (
        <div onClick={handleClick} className="brand-card cursor-pointer hover:shadow-lg bg-white p-12 flex justify-center items-center">
            <img src={img} alt="brand logo" className="w-full"/>
        </div>
    );
}

export default Card;
