import * as React from "react";
import { useState } from "react";
import ReactMapGL, { Marker } from "react-map-gl";
import {FaMapMarkerAlt} from "react-icons/fa"
const accessToken =
    "pk.eyJ1IjoiaHVzc2VpbnRhbGFsIiwiYSI6ImNrY3M4dWxwbzFtZDIycnM2OHQ4dXM4cnIifQ.ofCZrIlVF_r4YpQDzSi13g";
function Map() {
    const [viewport, setViewport] = useState({
        width: "100%",
        height: "60vh",
        latitude: 36.20607,
        longitude: 43.973392,
        zoom: 14,
    });

    return (
        <ReactMapGL
            {...viewport}
            onViewportChange={(nextViewport) => setViewport(nextViewport)}
            className="h-100"
            mapboxApiAccessToken={accessToken}
            
        >
            <Marker
                latitude={36.20607}
                longitude={43.973392}
                offsetLeft={-15}
                offsetTop={-20}
                onClick={() => {
                    window.open("https://goo.gl/maps/VFFfnfuGtckwHAzTA")
                }}
            >
                <FaMapMarkerAlt color="#f00" size="2rem"/>
            </Marker>
        </ReactMapGL>
    );
}

export default Map;
