import React from "react";
import BrandCard from "./brandCard/Card";
import Form from "./form/Form";
import ContactInfo from "./contact/ContactInfo";
import Map from "./map/Map";
import Footer from "../footer/Footer";
import { useEffect, useRef, useState } from "react";
import { FaInstagram, FaFacebook } from "react-icons/fa";
import { useTranslation } from "react-i18next";

function Home() {
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState("en");
  const brands = [
    {
      img: "/assets/logos/kryolan.svg",
      link: "https://global.kryolan.com/",
    },
    {
      img: "/assets/innovatis/logo.png",
      link: "https://innovatisworld.com/en/",
    },
    {
      img: "/assets/logos/3ve.png",
      link: "https://www.3me.biz",
    },
    {
      img: "/assets/logos/lisa.jpeg",
      link: "http://lisapitalia.com/en",
    },
  ];
  const [muted, setMuted] = useState(true);
  const vidRef = useRef(null);

  async function playVideo() {
    await vidRef.current.play();
  }
  const handleUnmute = () => {
    console.log("unmute");
    setMuted(!muted);
    vidRef.current.muted = muted;
  };
  useEffect(() => {
    playVideo();
    setLang(i18n.language);
  }, [i18n, i18n.language]);
  return (
    <>
      <main dir={lang === "ar" ? "rtl" : "ltr"}>
        <section id="hero">
          <video
            className="pt-16"
            onClick={handleUnmute}
            ref={vidRef}
            src="/assets/videos/sr-promo.mp4"
            autoPlay={true}
            loop="true"
            muted
            type="video/mp4"
          ></video>
        </section>

        <section
          id="about"
          className="min-h-screen bg-secondary flex"
          dir="ltr"
        >
          <div className="container services-container bg-blend-multiply mx-auto flex lg:flex-row flex-col justify-between items-center">
            <div className="lg:w-1/2 w-full lg:block  hidden">
              <img
                src="/assets/kryolan.png"
                className="transform rotate-180"
                alt="makeup"
              />
            </div>
            <div className="flex flex-col flex-row lg:w-1/2 lg:mt-0 mt-24 w-full">
              <h1
                className={`text-5xl font-bold mb-16 lg:text-dark text-primary  uppercase ${
                  lang === "ar"
                    ? "lg:text-right text-center"
                    : "lg:text-left text-center"
                }`}
              >
                {t("aboutUs.title")}
              </h1>
              <p
                dir={lang === "ar" ? "rtl" : "ltr"}
                className={` lg:px-0 px-4 text-xl lg:text-dark text-primary text-justify ${
                  lang === "ar"
                    ? "lg:text-right text-center"
                    : "lg:text-left text-center"
                }`}
              >
                {t("aboutUs.content")}
              </p>
            </div>
          </div>
        </section>

        <section
          id="brands"
          className="min-h-screen bg-primary flex flex-col py-24"
        >
          <div>
            <h1 className="text-5xl font-bold text-center uppercase">
              {t("brands.title")}
            </h1>
          </div>
          <div className="brands-container container mx-auto mt-16 flex justify-center md:justify-evenly align-center flex-wrap">
            {brands.map((brand) => (
              <BrandCard img={brand.img} link={brand.link} />
            ))}
          </div>
        </section>

        <section
          id="our-work"
          className="h-auto py-24 bg-secondary flex flex-col justify-center"
        >
          <div>
            <h1 className="text-5xl font-bold text-center uppercase">
              {t("services.title")}
            </h1>
          </div>
          <div className="subtitle text-center mt-8 flex justify-center text-xl">
            <h3 className="text-2xl text-center w-3/4 uppercase">
              {t("services.content")}
            </h3>
          </div>
        </section>

        <section id="brands" className="min-h-36 bg-white flex flex-col py-24">
          <div>
            <h1 className="text-5xl font-bold text-center uppercase">
              {t("followUs.title")}
            </h1>
          </div>
          <div className="brands-container container mx-auto mt-16 flex justify-center md:justify-evenly align-center flex-wrap">
            <a href="https://www.instagram.com/baitalwakalat.iq" target="blank">
              <FaInstagram className="social-media-icons" />
            </a>
            <a href="https://www.facebook.com/S.R.italiana.co" target="blank">
              <FaFacebook className="social-media-icons" />
            </a>
          </div>
        </section>

        <section id="info" className="min-h-screen flex  flex-col">
          <div>
            <Map />
          </div>
          <div id="contact-us" className=" w-full bg-dark h-auto px-4">
            <div className="mt-24 lg:px-0 px-4">
              <h1 className="text-5xl text-center font-bold text-primary uppercase">
                {t("contactUs.title")}
              </h1>
            </div>
            <ContactInfo />
            <Form />
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default Home;
